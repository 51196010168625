import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';


global.proxyServer = process.env.PROXY || require("../package.json").proxy;

ReactDOM.render(
    <App />,    
    document.getElementById('root')
);
