import React from 'react';
import "./Legal.scss";

import Document from "../../components/Document/Document";

export default function Legal () {
    return (
        <legal-page>
            <editor-wrapper>
                <Document document="/api/main/document/legal"/>
            </editor-wrapper>
        </legal-page>
    )
}
