import React, { Component } from 'react';
import { Redirect } from "react-router-dom";


import "./Portal.scss";

import UserContext from '../../UserContext';

// local components
import Login from "./Login/Login";
import Register from "./Register/Register";
import PasswordReset from "./PasswordReset/PasswordReset";
import Logout from "./Logout/Logout";

class Portal extends Component {

    static contextType  =  UserContext;


    state = {
        mode: "",
        message: "",
        loggedIn: false
    }

    constructor(){
        super();
        this.verify   = this.verify.bind(this);
        this.login    = this.login.bind(this);
        this.register = this.register.bind(this);
        this.reset    = this.reset.bind(this);
    }

    componentDidMount(){

        let mode = "login";
        switch (window.location.pathname) {
            case "/verify":
                mode = "verify";
                this.verify();
                break;
            case "/login":
                mode = "login";
                break;
            case "/register":
                mode = "register";
                break;
            case "/password-reset":
                mode = "password-reset";
                break;
            case "/logout":
                mode = "logout";
                break;
            default:
                break;
        }

        let state = this.state;
        state.mode = mode;
        state.loggedIn = this.context.admin;
        this.setState(state);
    }

    async verify(){
        let url = new URL(window.location);
        let verificationID = url.searchParams.get("verificationID");
        let email =          url.searchParams.get("email");

        await fetch(`/api/user/verify?verificationID=${verificationID}&email=${email}`)
        .then(response => {
            return response.json();
        })
        .then(json => {
            this.state.message = json.message;
            if(json.status && json.status.toLowerCase() === "success"){
                setTimeout(()=> {
                    window.location.replace("/login");
                }, 500);
            }
        })
        .catch(err => {
            console.log(err);
        });

        this.setState(this.state);
    }

    async login(data) {

        let { email, password } = data;
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        let raw = JSON.stringify({
            "email": email,
            "password": password
        });

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        await fetch("/api/user/login", requestOptions)
            .then(response => response.json())
            .then(json => {
                this.state.message = json.message;
                this.context.setAdmin(json.admin);
                this.setState(this.state);
                if(json.status && json.status.toLowerCase() === "success"){
                    setTimeout(()=> {
                        window.location.replace("/");
                    }, 500);
                }
            })
            .catch(error => console.log('error', error));
    }

    async register(data) {

        let { email, password } = data;

        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");


        let raw = JSON.stringify({
            "email": email,
            "password": password
        });

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("/api/user/register", requestOptions)
          .then(response => response.json())
          .then(json => {
                this.state.message = json.message;
                this.setState(this.state);
                if(json.status && json.status.toLowerCase() === "success"){
                    setTimeout(()=> {
                        window.location.replace("/");
                    }, 500);
                }
          })
          .catch(error => console.log('error', error));
    }

    reset(data) {
        let url = new URL(window.location);
        let { email, password, resetID} = data;

        let path = "/api/user/reset"
        if(!resetID || resetID === "")  {
            path = "/api/user/send-reset"
        }

        url.searchParams.get("resetID");

        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");


        let raw = JSON.stringify({
            "email": email,
            "password": password,
            "resetID": resetID
        });

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(path, requestOptions)
          .then(response => response.json())
          .then(json => {
              let state = this.state;
              state.message = json.message;
              this.setState(state);
              setTimeout(()=> {
                  window.location.replace("/login");
              }, 500);
           })
           .catch(error => console.log('error', error));
    }

    render() {

        let display = <></>;

        let url = new URL(window.location);

        switch (this.state.mode) {
            case "verify":
                display = <div>{ !url.searchParams.get("email") ? <Redirect to="/" /> : <></>}</div>;
                break;
            case "login":
                display =
                        <div>
                            <Login onSubmit={this.login}/>
                        </div>
                        ;
                break;
            case "register":
                display =   <div><Register onSubmit={this.register}/></div>;
                break;
            case "password-reset":
                display =
                        <div>
                            <PasswordReset onSubmit={this.reset}/>
                        </div>;
                break;
            case "logout":
                display = <div><Logout/>{}</div>;
                break;
            default:
                break;
        }

        return (
            <portal-page>
                <div id="portal-card">
                {this.state.message}
                {display}
                </div>
            </portal-page>
        );
    }

}

export default Portal;
