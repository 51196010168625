import React, { Component } from 'react';



import { DeviceMobileIcon, MailIcon } from '@primer/octicons-react';


import './Contact.scss';

//components
import ContactForm from './ContactForm/';

import placeholderImage from "../Landing/placeholder.webp";


import ScalableImage from "../../components/ScalableImage/ScalableImage"



class Contact extends Component {
    state = {
        contacts: [],
    }

    componentDidMount(){}
    componentDidUpdate(){}

    render() {
        return (
            <div id="contact-page">

                <div id="communication-lines">
                    <div id="filler">
                        <div id="filler-container">
                            <h3>Get In Contact With Us Today!</h3>
                            <div className="card">
                                <a id="phone-card"  href="tel:541-321-0056">
                                    <DeviceMobileIcon size={100} />
                                    <a>541-321-0056</a>
                                </a>
                                <a id="email-card" href="mailto:info@hellman.construction"  target="_blank" rel="noreferrer">
                                    <MailIcon size={100} />
                                    <div>info@hellman.construction</div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <ScalableImage img_src={process.env.PUBLIC_URL + '/plumbing-3.jpeg'} placeholder_img={placeholderImage}/>
                </div>
                <div id="communication-form">
                    <ContactForm/>
                </div>
            </div>
        );
    }

}

export default Contact;
