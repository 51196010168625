import React, { Component } from 'react';

import "./SetUp.scss";


import Domain from "./Domain/";
import Register from "./Register/";
import SMTPConfig from "./SMTPConfig/";

class SetUp extends Component {

    state = {}

    constructor(){
        super();
        this.updateProd = this.updateProd.bind(this);
    }

    async updateProd(){
        await fetch(`/api/server/state`, {method: "POST"})
        .then(response => {
            return response.json()
        })
        .then(json => {
            if(json.status === "success"){
                window.location.replace("/");
            }
        })
        .catch(err => {
            console.log(err);
        });
    }

    render() {
        return (
            <config-page>
                <h1>First Time Set Up :D</h1>
                <Domain/>
                <SMTPConfig/>
                <Register/>
                <button-container>
                    <button onClick={this.updateProd}>Done</button>
                </button-container>
            </config-page>
        );
    }

}

export default SetUp;
