import React, { Component } from 'react';

import { DraftailEditor, BLOCK_TYPE, INLINE_STYLE } from "draftail";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import {stateToHTML } from 'draft-js-export-html';



import "draft-js/dist/Draft.css";
import "draftail/dist/draftail.css";

import "./GalleryBody.scss";


let options = {
    inlineStyles: {
        BOLD: {element: 'b'},
        ITALIC: {element: 'i'},
        CODE: {element: "code", style: { backgroundColor: "rgba(27, 31, 35, 0.15)"}},
        MARK: {style: { "background-color": "yellow"} },
        REDACT: {style: { backgroundColor: 'currentcolor'} }
    },
    blockRenderers: {
        "atomic": (atomic) => {
            return '<hr class="Draftail-DividerBlock">';
        },
        "unstyled": (block) => {
            let text = block.get("text");;
            if (text === '\n') {
                return '<br>';
            }
        },

    },
    blockStyleFn: (block) => {
        let type = block.get("type");
        if (type === 'unstyled') {
            return {
                attributes: {
                    class: "Draftail-block--unstyled"
                }
            }
        }
    },
    entityStyleFn: (entity) => {
    },
}


class Document extends Component {

    blocksTypes = []
    inlineStyles =  [
        {
            label: 'Redact',
            type: 'REDACT',
            style: {
                backgroundColor: 'currentcolor',
            },
        }
    ]

    constructor() {
        super()

        this.state = {
            loaded: false,
            draft:  null,
            editmode: false
        }

        for(let t in BLOCK_TYPE) this.blocksTypes.push({type:  BLOCK_TYPE[t]})
        for(let t in INLINE_STYLE) this.inlineStyles.push({type:  INLINE_STYLE[t]})

        this.toggle = this.toggle.bind(this);
        this.draftInit = this.draftInit.bind(this);
        this.draftSave = this.draftSave.bind(this);
    }

    componentDidMount(){
    }

    componentDidUpdate(){
        if(this.props.loaded && this.state.loaded === false){
            let state = this.state;
            state.loaded = true;
            this.setState(state);
            this.draftInit();
        }
    }

    draftInit () {
        let s = this.state;
        let json = this.props.data;
        try {
            convertFromRaw(json);
            s.draft = json;
        }
        catch (err) {
            let obj =  EditorState.createEmpty().getCurrentContent();
            s.draft =  convertToRaw(obj);
        }
        this.setState(s);
    }
    draftSave(content) {

        if (this.props.loaded !== true) return;
        if (content === null) {
            content = convertToRaw(EditorState.createEmpty().getCurrentContent());
        }

        let s = this.state;
        s.draft = content;
        this.setState(s);

        if(typeof this.props.onSave === "function"){
            this.props.onSave(content);
        }
    }

    editor(){
        return <DraftailEditor
                rawContentState={this.state.draft}
                onSave={this.draftSave}
                blockTypes={this.blocksTypes}
                inlineStyles={this.inlineStyles}
                stripPastedStyles={false}
                enableHorizontalRule
                enableLineBreak
                showUndoControl
                showRedoControl
                spellCheck
            />
    }

    viewer() {
        let state;
        try {
            state =  convertFromRaw(this.state.draft);
        }
        catch (err){
            state = EditorState.createEmpty().getCurrentContent();
        }
        let html = stateToHTML(state, options);
        return <div dangerouslySetInnerHTML={{__html: html}}/>
    }

    toggle(){
        let state = this.state;
        this.setState(state);
    }

    render() {

        let view =  this.props.editmode ? this.editor() : this.viewer()
        return (
            <>
                <div id="doc-editor">
                    {
                        this.props.loaded ? view : <></>
                    }
                </div>
            </>
        );
    }
}



export default Document;
