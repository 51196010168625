import React, { Component } from 'react';
import './GalleryPanel.scss';

import { Link } from "react-router-dom";


class GalleryPanel extends Component {

    render() {
        return (
            <gallery-panel onClick={this.props.onClick}>
                <Link to={`/gallery/${this.props.data.id}`}>
                    <img src={this.props.thumbnail} alt={this.props.thumbnail}/>
                    <div id="gallery-panel-title-container">
                        <div>{this.props.data.title === ""  || !this.props.data.title ? "Project Item" : this.props.data.title}</div>
                    </div>
                </Link>
            </gallery-panel>
        );
    }

}

export default GalleryPanel;
