import React, { Component, useState, useEffect } from 'react';
import { ChevronLeftIcon , ChevronRightIcon, XCircleIcon, TrashIcon, PlusCircleIcon, UploadIcon, PencilIcon, EyeIcon, StarFillIcon, StarIcon } from '@primer/octicons-react';


import {v4} from "uuid";
import axios from 'axios';
import "./FileDash.scss";



import Upload from "./Upload/";

function FileDash(props){

    let [state, setState] = useState({
        uploads: [],
        page: 0,
        files: {},
        selected: [],
        loaded : false,
    });

    let getMedia = () => {
        axios.get("/api/media/all")
        .then(response => {
            let {data : { files = {} } } = response;
            setState({
                ...state,
                loaded: true,
                files
            });
        });
    };


    if(state.loaded === false){
        getMedia();
    }



    let toggleSelect = (event) => {
        let file = event.currentTarget.dataset.file;
        let selected = state.selected;    
        // multi selection
        if(event.ctrlKey && props.multiSelect){
            if(selected.includes(file)) {
                // deselection
                selected = selected.filter( f => f !== file );
            } else {
                selected.push(file);
            }
        } else { // single selection
            selected = [file];            
        }


        setState({
            ...state,
            selected,
            loaded: false,
        });
    };

    let uploadFile = (event) => {
        for(let f of event.target.files) {
            let upload = <Upload key={v4()} file={f} url={"/api/media"}/>;
            let uploads = state.uploads;
            uploads.push(upload);
            setState({
                ...state,
                uploads,
            });
        }
    };

    let setPage = (event) => {
        setState({
            ...state,
            page: event.target.dataset.page,
            loaded: false,
        });
    };


    let deleteSelection = async () => {
        for(let n of state.selected){
            await axios.delete("/api/media/" + n)
            .then(response => {
                let files = state.files;
                delete files[n];
                setState({
                    ...state,
                    files,
                    loaded: false,
                });
                console.log("Item Removed")
            })
            .catch(err => {
                console.log(err);
            });
        }
    };


    
    let useSelection = () => {
        let s = state.selected.map( s => `/api/media/${s}`);
        if(state.uploads) {
            // eslint-disable-next-line
            let result = confirm("If Uploads are active they could be lost, \nAre you sure you want to continue?");
            if(!result) {
                return;
            }
            props.onSelection(s);
        } else {
            props.onSelection(s);
        }
    };

    let close = () => {
        if(state.uploads) {
            // eslint-disable-next-line
            let result = confirm("If Uploads are active they could be lost, \nAre you sure you want to continue?");
            if(!result) {
                return;
            }
            props.onClose();
        } else {
            props.onClose();
        }
    };


    let { files, uploads, page} = state;

    return (
        <div className="file-dash">
            <div className="dash-panel">
                <div className="top-bar">
                    <div className="top-header">
                        File Dash
                    </div>
                    <div className="controls">
                        <button onClick={close}>X</button>
                    </div>
                </div>
                <div className="page-viewer">
                    <div className="page-viewer-controls">
                        <button type="button" data-page={0} onClick={setPage}>Upload</button>
                        <button type="button" data-page={1} onClick={setPage}>Manage</button>
                    </div>
                    <div className="page page-1" style={{ display: page !== "0" ? "none" : ""}}>
                        <div className="file-area">
                            <div className="area-action">
                                <input id="file-input" type="file" onChange={uploadFile} accept="video/*, image/*" hidden multiple/>
                                <label id="add-media"  htmlFor="file-input">
                                    <PlusCircleIcon size={"25px"}/>
                                    <div>Upload Media</div>
                                </label>
                            </div>
                        </div>
                        <div className="upload-list">
                            {state.uploads.reverse()}
                        </div>
                    </div>
                    <div className="page page-2" style={{ display: state.page !== "1" ? "none" : ""}}>
                        <div className="media-selection">
                            {
                                Object.values(state.files).map((file, index) => {
                                    let key = Object.keys(state.files)[index];
                                    let selected = state.selected.includes(key);

                                    return (
                                        <div key={key} className="media-item">
                                            <a data-file={key} onClick={toggleSelect}>
                                                <div className="media-icon-container">
                                                    <img src={`/api/media/${key}?thumbnail=true`}/>
                                                </div>
                                                <div className="media-details-container">
                                                    <div>
                                                        <div style={{overflowX: "auto"}}>{file.name}</div>
                                                        <p>Type: {file.mediaFormat}</p>
                                                        <p>Status: {file.state}</p>
                                                    </div>
                                                </div>
                                                <div style={{display: "flex"}}>
                                                    <div style={{margin: "auto", padding: "10px"}}>
                                                        {selected ? "👍" : ""}
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    );
                                })
                            }
                        </div>
                        <div className="media-actions">
                            <button type="button" className="delete" onClick={deleteSelection}>Delete</button>
                            <button type="button" className="submit" onClick={useSelection}>Add</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FileDash;
