import React, { Component } from 'react';
import "./NavDrawer.scss";

import { NavLink } from "react-router-dom";

import UserContext from '../../UserContext.js';

class NavDrawers extends Component {

    constructor(){
        super();
        this.onClicked = this.onClicked.bind(this);
    }

    onClicked() {
        if (typeof this.props.onLinkClick === "function"){
            this.props.onLinkClick();
        }
    }

    render() {

        return (
            <nav-drawers class={this.props.open ? "open" : "closed"}>
                <Drawer to="/home" onClick={this.onClicked}>Home</Drawer>
                <Drawer to="/gallery" onClick={this.onClicked}>Gallery</Drawer>
                <Drawer to="/quote" onClick={this.onClicked}>Quote</Drawer>
                <Drawer to="/contact" onClick={this.onClicked}>Contact</Drawer>
                <Drawer to="/about" onClick={this.onClicked}>About Us</Drawer>
                <Drawer to="/policy" onClick={this.onClicked}>Policies</Drawer>
                <AdminNeeded>
                    <Drawer to="/logout" onClick={this.onClicked}>Logout</Drawer>
                </AdminNeeded>
            </nav-drawers>
        );
    }

}


function AdminNeeded(props){

    let childs = <>{props.children}</>;
    return (
        <UserContext.Consumer>
        {
            data => {
                if (data.admin){
                    return <>{childs}</>;
                }
                return <></>;
            }
        }
        </UserContext.Consumer>
    );
}

class Drawer extends Component {

    render() {
        return (
            <NavLink exact to={this.props.to} id={"nav-drawer"} activeClassName="drawer-selected" onClick={this.props.onClick}>
                    <div id="nav-drawer-inner">{this.props.children}</div>
            </NavLink>
        );
    }

}

export default NavDrawers;
