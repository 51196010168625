import React, { Component } from 'react';

import { Formik, Field} from 'formik';
import * as yup from 'yup';

import Cover from "../Cover/";


const registerSchema = yup.object().shape({
    email: yup.string()
       .email("Email must be Valid")
       .required("Email Is A Required Field"),
    password: yup.string()
      .min(5, "Password Needs To Between 5 and 120 characters!")
      .max(120, "Password Needs To Between 5 and 120 characters!")
      .required("Password Is A Required Field"),
});


class Register extends Component {

    state = {
        message: null,
        success: false
    }
    constructor() {
        super();
        this.submit = this.submit.bind(this);
    }

    submit(data) {
        let { email, password } = data;

        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");


        let raw = JSON.stringify({
            "email": email,
            "password": password
        });

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("/api/server/create-admin", requestOptions)
          .then(response => response.json())
          .then(json => {
                let state = this.state;
                state.message = json.message || "";
                if( json.status === "success" ){
                    state.success = true;
                    this.setState(state);
                }
          })
          .catch(error => console.log('error', error));
    }

    render() {
        return (
            <register-comp>
                <Formik
                    initialValues={{email: "", password: ""}}
                    validationSchema={registerSchema}
                    onSubmit={this.submit}
                >
                {
                formik => (
                    <>
                        <h2>Create Admin</h2>
                        { this.state.message ? <div style={{color: this.state.success ? "green" : "red"}}>{this.state.message}</div> : <></>}
                        <div>
                        {
                            Object.keys(formik.errors).map(c => formik.touched[c] && formik.errors[c] ? <div>{formik.errors[c]}</div> : <></>)
                        }
                        </div>
                        <form autoComplete="off" onSubmit={formik.handleSubmit}>
                            <Input type="email"    formik={formik}>Email</Input>
                            <Input type="password" formik={formik}>Password</Input>
                            <button type="submit">Submit</button>
                            <Cover display={this.state.success ? "flex" : "none"}/>
                        </form>

                    </>
                )
                }
                </Formik>
            </register-comp>
        );
    }
}


function Input(props){
    let type = props.type;
    let formik = props.formik;
    let error = formik.touched[type] && formik.errors[type] ? "login-error" : "";
    return (
        <>
            <input-container>
                <Field component="input"  autoComplete={props.autoComplete} className={"login-input " + error} type={type} {...formik.getFieldProps(type)} placeholder={props.children}/>
            </input-container>
        </>
    )
}

export default Register;
