import React, { Component} from 'react';

import "./About.scss";


import Document from "../../components/Document/Document"


class About extends Component {

    render() {
        return (
            <about-page>
                <editor-wrapper>
                    <Document  document="/api/main/document/about"/>
                </editor-wrapper>
            </about-page>
        );
    }

}

export default About;
