import React, { Component } from 'react';

import { Formik, Field} from 'formik';
import * as yup from 'yup';

const registerSchema = yup.object().shape({
    email: yup.string()
       .email("Email must be Valid")
       .required("Email Is A Required Field"),
    password: yup.string()
      .min(5, "Password Needs To Between 5 and 120 characters!")
      .max(120, "Password Needs To Between 5 and 120 characters!")
      .required("Password Is A Required Field"),
});


class Register extends Component {
    constructor() {
        super();
        this.submit = this.submit.bind(this);
    }

    submit(data) {
        this.props.onSubmit(...arguments);
    }

    render() {
        return (
            <Formik
                initialValues={{email: "", password: ""}}
                validationSchema={registerSchema}
                onSubmit={this.submit}
            >
            {
            formik => (
                <>
                    <h1>Register</h1>
                    <div>
                    {
                        Object.keys(formik.errors).map((c, i) => formik.touched[c] && formik.errors[c] ? <div key={i}>{formik.errors[c]}</div> : <></>)
                    }
                    </div>
                    <form onSubmit={formik.handleSubmit}>
                        <Input type="email" formik={formik}>Email</Input>
                        <Input type="password" formik={formik}>Password</Input>
                        <button type="submit">Submit</button>
                    </form>
                </>
            )
            }
            </Formik>
        );
    }
}


function Input(props){
    let type = props.type;
    let formik = props.formik;
    let error = formik.touched[type] && formik.errors[type] ? "login-error" : "";
    return (
        <>
            <div className="login-container" id={type}>
                <Field component="input" className={"login-input " + error} id={type} type={type} {...formik.getFieldProps(type)} placeholder={props.children}/>
            </div>
        </>
    )
}

export default Register;
