// components
import UserContext from './UserContext';

import React, { Component} from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";


import './App.scss';

import NavBar from './components/NavBar/NavBar';
import Footer from './components/Footer/Footer';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';


import LoadingScreen from './views/LoadingScreen/LoadingScreen';
import About from './views/About/About';
import Landing from './views/Landing/Landing';
import Legal from './views/Legal/Legal';
import Contact from './views/Contact/Contact';
import Portal from './views/Portal/Portal';
import Gallery from './views/Gallery/Gallery';
import Quote from './views/Quote';




import SetUp from './views/SetUp/SetUp';

class App extends Component {
    // Context
    static contextType  =  UserContext;

    constructor() {
        super();

        this.state = {
            loaded: 0,
            active: false,
            context: {
                admin: false,
                setAdmin: false
            },
            production: true,
        }
        this.init = this.init.bind(this);
    }

    async componentDidMount() {
        this.init();
    }

    componentDidUpdate() {

    }


    async init() {
        // this.state.context = this.context;
        await fetch("/api/server/state")
            .then(response => response.json())
            .then(json => {
                if(json.production === false){
                    this.state.production = false;
                }
                this.state.loaded = 50;
            })
            .catch(err => {
                this.state.loaded = 50;
            });

        await fetch("/api/user/whoami")
            .then(response => response.json())
            .then(json => {
                let { setAdmin } = this.context;
                setAdmin(json.data.admin);
                this.state.loaded = 100;

            })
            .catch(err => {
                this.state.loaded = 100;
            });

        this.setState(this.state);
    }


    render() {
        if(this.state.loaded === 100 && this.state.active === false) {
            setTimeout(() => {
                this.setState({active: true});
            }, 100);
        }

        if(this.state.production === false) return <SetUp/>;
        return (
            <>
            {
                this.state.active? (
                    <Router>
                        <UserContext.Provider value={this.context}>
                        <NavBar/>
                        <div id="app-body">
                            <ScrollToTop/>
                            <Switch>
                                <Route exact path="/">
                                    <Redirect to="/home"/>
                                </Route>
                                <Route exact path="/home">
                                    <Landing/>
                                </Route>
                                <Route exact path="/about">
                                    <About/>
                                </Route>
                                <Route exact path="/quote">
                                    <Quote/>
                                </Route>
                                <Route exact path="/contact">
                                    <Contact/>
                                </Route>
                                <Route exact path="/gallery/:galleryId?" component={Gallery}/>
                                <Route exact path="/(privacy|legal|privacy-policy|cookies|cookies-policy|policy)">
                                    <Legal/>
                                </Route>
                                <Route exact path="/(portal|login|register|verify|logout|password-reset)">
                                    <Portal/>
                                </Route>
                                <Route path="/404" status={404}>
                                    <div>404</div>
                                </Route>
                                <Route>
                                    <Redirect to="/404"/>
                                </Route>
                            </Switch>
                        </div>
                        <Footer/>
                        </UserContext.Provider>
                    </Router>
                ) : (
                    <LoadingScreen progress={this.state.loaded}/>
                )
            }

            </>
        );
    }
}

export default App;
