import React, { Component } from 'react';
import { DeviceMobileIcon, MailIcon, HeartFillIcon } from '@primer/octicons-react';
import { Link } from "react-router-dom";

import "./Footer.scss";
import Logo from "./ConstructionVector.svg";



class Footer extends Component {

    render() {
        return (
            <>
                <div id="app-footer">
                    <div id="top-footer">
                        <div id="top-left-footer"></div>
                        <div id="top-mid-footer">
                            <h1 id="footer-name">Hellman Plumbing And General Construction</h1>
                        </div>
                        <div id="top-right-footer"></div>
                    </div>
                    <div id="mid-footer">
                        <div id="mid-left-footer">
                            <div id="footer-additional-links">
                                <div>CCB: 232076</div>
                                <Link to="/privacy">Our Policies</Link>
                            </div>
                        </div>
                        <div id="mid-mid-footer">
                            <div id="footer-logo">
                                <img src={Logo} alt="logo"></img>
                            </div>
                        </div>
                        <div id="mid-right-footer">
                            <div id="footer-contact-items">
                                <h2 id="footer-contact-title">Contact Us</h2>
                                <div id="footer-phone" className="footer-link">
                                    <DeviceMobileIcon size={25} />
                                    <a href="tel:541-321-0056">Phone: 541-321-0056</a>
                                </div>
                                <div id="footer-email" className="footer-link">
                                    <MailIcon size={25} />
                                    <a href="mailto:info@hellman.construction"  rel="noreferrer" target="_blank">Email: info@hellman.construction</a>
                                </div>
                                <h2 id="footer-contact-title">Our Location</h2>
                                <div>
                                    <a href="https://goo.gl/maps/KQwbaGF4cqjG8gw77"   rel="noreferrer"  target="_blank">Hermiston, OR</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="bottom-footer">
                        <div id="bottom-left-footer"></div>
                        <div id="bottom-mid-footer"></div>
                        <div id="bottom-right-footer">
                            <div id="footer-avalon-plug"><a target="_blank"  rel="noreferrer"  href="http://avalonexploit.com/"><HeartFillIcon size={25} /><span>&nbsp;&nbsp;This Application Was Made With Love - Avalon Software and Infrastructure</span></a></div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

}

export default Footer;
