import React, { Component } from 'react';

import { Formik, Field} from 'formik';
import * as yup from 'yup';

import Cover from "../Cover/";


const smtpSchema = yup.object().shape({
    email: yup.string()
       .email("Email must be Valid")
       .required("Email Is A Required Field"),
    password: yup.string()
        .min(5, "Password Needs To Between 5 and 120 characters!")
        .max(120, "Password Needs To Between 5 and 120 characters!")
        .required("Password Is A Required Field"),
    server: yup.string()
        .matches(/((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
            'Enter correct url!'
        )
        .required('Server is A Required Field'),
    port: yup.string()
        .min(2, "")
        .max(5, "A Port Needs To Between two and 5 characters")
        .required("Port Is A Required Field"),
});

class SMTPConfig extends Component {

    state = {
        email: "",
        server: "",
        port: "",
        password: "",
        loaded: false,
        success: false
    }

    constructor(){
        super();
        this.init   = this.init.bind(this);
        this.submit = this.submit.bind(this);
    }

    componentDidMount(){
        this.init();
    }

    async init(){
        await fetch("/api/server/smtp")
          .then(response => response.json())
          .then(json => {
              if(json.status === "success"){
                  let { email, server, port} = json;
                  this.setState({email, server, port, loaded: true});
              }
          })
          .catch(error => {
              this.state.loaded = true;
              this.setState(this.state);
          });
    }


    async submit(data){
        let { email, password, server, port} = data;
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        let raw = JSON.stringify({
            email,
            password,
            server,
            port
        });

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        await fetch("/api/server/smtp", requestOptions)
            .then(response => response.json())
            .then(json => {

                let state = this.state;
                if( json.status=== "success" ){
                    state.success = true;
                    this.setState(state);
                }
                state.message = json.message;
                this.setState(state);
            })
            .catch(error => console.log('error', error));
    }

    render() {
        if (this.state.loaded === false) return <></>;
        return (
            <smtp-config>
                <Formik
                    initialValues={this.state}
                    validationSchema={smtpSchema}
                    onSubmit={this.submit}
                >
                {
                formik => (
                    <>
                        <h2>SMTP Config</h2>
                        { this.state.message ? <div style={{color: this.state.success ? "green" : "red"}}>{this.state.message}</div> : <></>}
                        <div>
                        {
                            Object.keys(formik.errors).map(c => formik.touched[c] && formik.errors[c] ? <div>{formik.errors[c]}</div> : <></>)
                        }
                        </div>
                        <form autoComplete="off" onSubmit={formik.handleSubmit}>
                            <Input type="email" formik={formik}>No-Reply Email</Input>
                            <Input type="server" formik={formik}>Server</Input>
                            <Input type="port" formik={formik}>Port</Input>
                            <Input type="password" formik={formik}>Password</Input>
                            <button type="submit">Push</button>
                            <Cover display={this.state.success ? "flex" : "none"}/>
                        </form>
                    </>
                )
                }
                </Formik>
            </smtp-config>
        );
    }

}

function Input(props){
    let type = props.type;
    let formik = props.formik;
    return (
        <>
            <input-container>
                <Field component="input" className={type} type={type}  {...formik.getFieldProps(type)} placeholder={props.children}/>
            </input-container>
        </>
    )
}

export default SMTPConfig;
