import React, { Component } from 'react';

import { DraftailEditor, BLOCK_TYPE, INLINE_STYLE } from "draftail"
import { EditorState, convertFromRaw, convertToRaw } from "draft-js"
import {stateToHTML } from 'draft-js-export-html';


import UserContext from "../../UserContext"

import "draft-js/dist/Draft.css"
import "draftail/dist/draftail.css"

import "./Document.scss";


let options = {
    inlineStyles: {
        BOLD: {element: 'b'},
        ITALIC: {element: 'i'},
        CODE: {element: "code", style: { backgroundColor: "rgba(27, 31, 35, 0.15)"}},
        MARK: {style: { "background-color": "yellow"} },
        REDACT: {style: { backgroundColor: 'currentcolor'} }
    },
    blockRenderers: {
        "atomic": (atomic) => {
            return '<hr class="Draftail-DividerBlock">';
        },
        "unstyled": (block) => {
            let text = block.get("text");;
            if (text === '\n') {
                return '<br>';
            }
        },

    },
    blockStyleFn: (block) => {
        let type = block.get("type");
        if (type === 'unstyled') {
            return {
                attributes: {
                    class: "Draftail-block--unstyled"
                }
            }
        }
    },
    entityStyleFn: (entity) => {
    },
}


class Document extends Component {

    blocksTypes = []
    inlineStyles =  [
        {
            label: 'Redact',
            type: 'REDACT',
            style: {
                backgroundColor: 'currentcolor',
            },
        }
    ]

    constructor() {
        super()

        this.state = {
            loaded: false,
            draft:  null,
            editmode: false
        }

        for(let t in BLOCK_TYPE) this.blocksTypes.push({type:  BLOCK_TYPE[t]})
        for(let t in INLINE_STYLE) this.inlineStyles.push({type:  INLINE_STYLE[t]})

        this.toggle = this.toggle.bind(this);
        this.draftInit = this.draftInit.bind(this);
        this.draftSave = this.draftSave.bind(this);
    }

    componentDidMount(){
        this.draftInit()
    }
    async draftInit () {
        let s = this.state;
        let json = null

        await fetch( this.props.document, {method: "GET", redirect: 'follow'})
            .then(response => response.json())
            .then(_json => {
                json = _json
                s.loaded = true;
            })
            .catch(error => {
                console.log('error', error)
                s.loaded = true;
                return {}
            });
        try {
            convertFromRaw(json);
            s.draft = json;
        }
        catch (err) {
            let obj =  EditorState.createEmpty().getCurrentContent();
            s.draft =  convertToRaw(obj);
        }

        this.setState(s);
    }
    draftSave(content) {

        if (this.state.loaded !== true) return;
        if (content === null) {
            content = convertToRaw(EditorState.createEmpty().getCurrentContent());
        }

        // console.log("Save", content)
        let s = this.state;
        s.draft = content;
        this.setState(s)

        let headers = new Headers();
        headers.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'POST',
            headers: headers,
            body:  JSON.stringify(s.draft),
        };

        fetch(this.props.document, requestOptions)
          .then(response => response.text())
          .then(result => {}/*console.log(result)*/)
          .catch(error => console.log('error', error));
    }

    editor(){
        return <DraftailEditor
                rawContentState={this.state.draft}
                onSave={this.draftSave}
                blockTypes={this.blocksTypes}
                inlineStyles={this.inlineStyles}
                stripPastedStyles={false}
                enableHorizontalRule
                enableLineBreak
                showUndoControl
                showRedoControl
                spellCheck
            />
    }

    viewer() {
        let state;
        try {
            state =  convertFromRaw(this.state.draft);
        }
        catch (err){
            state = EditorState.createEmpty().getCurrentContent();
        }

        // console.log("Data", this.state.draft);
        // console.log("State",state);
        let html = stateToHTML(state, options);


        return <div dangerouslySetInnerHTML={{__html: html}}/>
    }

    toggle(){
        let state = this.state;
        state.editmode = !this.state.editmode;
        this.setState(state);
    }

    render() {
        let editbutton =  (
            <UserContext.Consumer>
                {
                    data => {
                        if (data.admin){
                            return <button onClick={this.toggle}>{!this.state.editmode?<>Edit</>:<>View</>}</button>
                        }
                        return <></>
                    }
                }
            </UserContext.Consumer>
        )
        let view =  this.state.editmode ? this.editor() : this.viewer()
        return (
            <>
                <div id="doc-editor">
                    <top-bar>{editbutton}</top-bar>

                    {
                        this.state.loaded ? view : <></>
                    }
                </div>
            </>
        );
    }
}



export default Document;
