import React, { Component } from 'react';
import "./LoadingBar.scss";

class LoadingBar extends Component {
    render() {
        return (
            <loading-bar style={{
                "--loading-bar-width": this.props.width || "100%",
                "--loading-bar-height": this.props.height || "100%",
            }}>
                <outer-bar>
                    <inner-bar style={{
                        "--loading-bar-percentage": this.props.percentage + "%",
                    }}/>
                </outer-bar>
            </loading-bar>
        );
    }
}
export default LoadingBar;
