import React, { Component } from 'react';
import { Formik, Field} from 'formik';
import * as yup from 'yup';



const resetSchema = yup.object().shape({
    email: yup.string()
       .email("Email must be Valid")
       .required("Email Is A Required Field"),
    password: yup.string()
       .min(5, "Password Needs To Between 5 and 120 characters!")
       .max(120, "Password Needs To Between 5 and 120 characters!")
       .required("Password Is A Required Field"),
});


const rSchema = yup.object().shape({
    email: yup.string()
       .email("Email must be Valid")
       .required("Email Is A Required Field")
});



class PasswordReset extends Component {


    componentDidMount() {

    }

    constructor() {
        super();
        this.submit = this.submit.bind(this);
    }

    submit(data) {
        this.props.onSubmit(...arguments);
    }

    render() {

        let url = new URL(window.location);
        let resetID = url.searchParams.get("resetID");

        if (resetID && resetID !== ""){
            return (

                <Formik
                    initialValues={{email: "", password: "", resetID}}
                    validationSchema={resetSchema}
                    onSubmit={this.submit}
                >
                {
                formik => (
                    <>
                        <h1>Password Reset</h1>
                        <div>
                        {
                            Object.keys(formik.errors).map((c, i) => formik.touched[c] && formik.errors[c] ? <div key={i}>{formik.errors[c]}</div> : <></>)
                        }
                        </div>
                        <form onSubmit={formik.handleSubmit}>
                            <Input type="email" formik={formik}>Email</Input>
                            <Input type="password" formik={formik}>Password</Input>
                            <button type="submit">Submit</button>
                        </form>
                    </>
                    )
                }
                </Formik>
            );
        } else {
            return (
                <Formik
                    initialValues={{email: ""}}
                    validationSchema={rSchema}
                    onSubmit={this.submit}
                >
                {
                formik => (
                    <>
                        <h1>Password Reset</h1>
                        <div>
                        {
                            Object.keys(formik.errors).map((c, i) => formik.touched[c] && formik.errors[c] ? <div key={i}>{formik.errors[c]}</div> : <></>)
                        }
                        </div>
                        <form onSubmit={formik.handleSubmit}>
                            <Input type="email" formik={formik}>Email</Input>
                            <button type="submit">Submit</button>
                        </form>
                    </>
                    )
                }
                </Formik>
            );
        }
    }

}

function Input(props){
    let type = props.type;
    let formik = props.formik;
    let error = formik.touched[type] && formik.errors[type] ? "login-error" : "";
    return (
        <>
            <div className="login-container" id={type}>
                <Field component="input" className={"login-input " + error} id={type} type={type} {...formik.getFieldProps(type)} placeholder={props.children}/>
            </div>
        </>
    )
}

export default PasswordReset;
