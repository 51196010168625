import React, { Component } from 'react';

import "./ScalableImage.scss"

class ScalableImage extends Component {

    render() {
        return (
            <>
                <img id="scalable-image" src={this.props.img_src} alt="Landing"/>
            </>
        );
    }

}

export default ScalableImage;
