import {useState} from 'react';

import "./MediaRender.scss";
// This will help bounce between images and videos
function MediaRenderer (props){

    let [mime, setMime] = useState("");
    let [loaded, setLoaded] = useState(false);
    try {
        fetch(props.src + "/info", {method: "GET", redirect: 'follow'})
        .then(response => {
            return response.json();
        })
        .then(data => {
            console.log(data)
            let {mediaFormat, mediaType, mimetype : { mime }} = data;
            setMime(`${mediaFormat}/${mediaType}`);
            setLoaded(true);
        });
    } catch (error) {
        console.error(error);
        setLoaded(false);
    }


    let mediaElement = <div className="render-message"><div>No Media is available to render</div></div>;
    // if the media type is a video
    if(mime.includes("video")){
        // this will force the video element to re-render
        let loadedAt = new Date().valueOf();
        mediaElement =  (
            <video key={loadedAt} className="media-display" alt="Missing Gallery Item" poster={props.src + "?thumbnail=true"} controls autoPlay={false}>
                <source src={props.src} type={mime} />
            </video>
        );
    }
    // if the media type is an image
    else if(mime.includes("image")){
        mediaElement = <img className="media-display" src={props.src} alt="Missing Gallery Item"/>
    }
    // if no media type was specified
    else if(mime.length){
        mediaElement =  <div className="renderer-message"><div>Unknown Media Type XO</div></div>
    }
    if(!loaded && props.src) mediaElement =  <div className="render-message"><div>loading...</div></div>;

    return (
        <div className="media-renderer">
            <div>
                {mediaElement}
            </div>
        </div>
    );
}


export default MediaRenderer;
