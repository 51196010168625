import React, { Component } from 'react';
import "./LoadingScreen.scss";

import LoadingBar from '../../components/LoadingBar/LoadingBar';
import Logo from './ConstructionVector.png';


class LoadingScreen extends Component {

    preventDefault = e => e.preventDefault();

    render() {
        return (
            <>
                <loading-screen>
                    <loading-screen-center>
                        <loading-logo>
                            <img alt="HPGC" src={Logo} onDragStart={this.preventDefault} onContextMenu={this.preventDefault}/>
                        </loading-logo>
                        <loading-bar-container>
                            <LoadingBar percentage={this.props.progress} height="20px" border=""/>
                        </loading-bar-container>
                    </loading-screen-center>
                </loading-screen>
            </>
        );
    }

}

export default LoadingScreen;
