import React, { Component, useState } from 'react';
import { ImageIcon, ChevronLeftIcon , ChevronRightIcon, XCircleIcon, TrashIcon, PlusCircleIcon, UploadIcon, PencilIcon, EyeIcon, StarFillIcon, StarIcon } from '@primer/octicons-react';

import UserContext from '../../../UserContext';
import GalleryBody from "../../../components/GalleryBody/GalleryBody";
import MediaRender from "../../../components/MediaRender/";
import FileDash from "../../../components/FileDash/";



import axios from 'axios';

import "./GalleryViewer.scss";


class GalleryViewer extends Component {

    state = {
        gallery: {
            media : [],
            thumbnail: "",
            title : "",
            body: {},
        },
        editmode: false,
        index : 0,
        media : [],
        thumbnail: "",
        title : "",
        body: {},
        loaded: false,
        fileDash: false,
        thumbailChange: false,
    }

    constructor(){
        super();
        this.closeViewer = this.closeViewer.bind(this);
        this.forward = this.forward.bind(this);
        this.back = this.back.bind(this);
        this.getGalleryData = this.getGalleryData.bind(this);
        this.setGalleryData = this.setGalleryData.bind(this);
        this.actionButton = this.actionButton.bind(this);
        this.updateMedia = this.updateMedia.bind(this);
        this.viewMedia = this.viewMedia.bind(this);
        this.toggle = this.toggle.bind(this);
        this.updateThumbnail = this.updateThumbnail.bind(this);
        this.toggleFileDash = this.toggleFileDash.bind(this);

        this.addMedia = this.addMedia.bind(this);
    }

    componentDidMount(){


        this.getGalleryData();
        // enable or disable body scroll
        document.querySelector("body").classList.add("body-no-scroll");
    }

    componentWillUnmount(){
        document.querySelector("body").classList.remove("body-no-scroll");
    }

    componentDidUpdate() {
        // console.log(this.state)
    }

    toggle(editmode){
        let state = this.state;
        state.editmode = editmode;
        this.setState(state);
    }

    // next image
    forward(){
        let state = this.state;
        if(this.state.media.length >= 1){
            state.index++;
            if (state.index === this.state.media.length){
                state.index = 0;
            }
        }
        this.setState(state);
    }



    // next image
    back(){
        let state = this.state;
        if(this.state.media.length >= 1){
            state.index--;
            if (state.index === -1){
                state.index = this.state.media.length -1;
            }
        }
        this.setState(state);
    }


    // Pull all the data needed for the gallery
    getGalleryData(){
        fetch( "/api/gallery/" + this.props.gallery, {method: "GET", redirect: 'follow'})
        .then(response => response.json())
        .then(json => {
            let {body, media, title, thumbnail} = json.data;
            let state = this.state;
            state.media = media;
            state.title = title;
            state.body  = body;
            state.thumbnail = thumbnail;
            state.loaded = true;

            this.setState(state);
        })
        .catch(error => {
            console.error("Thumb Error", error);
        });
    }

    // Pull all the data needed for the gallery
    setGalleryData(data){
        let state = this.state;
        state.body = data;
        this.setState(state);

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify(this.state);

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("/api/gallery/" + this.props.gallery, requestOptions)
            .then(response => {
                response.json();
            })
            .then(result => {
                this.setState(state);
            })
            .catch(error => console.log('error', error));
    }


    //replace me
    async updateThumbnail(media){
        let thumbnail = media.pop();
        let state = this.state;

        console.log(`Setting Thumb: ${thumbnail}`)
        state.thumbnail = thumbnail + "?thumbnail=true"
        this.setState(state);

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify(this.state);

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        await fetch("/api/gallery/" + this.props.gallery, requestOptions)
            .then(response => {
                response.json();
            })
            .then(result => {
                this.setState(state);
            })
            .catch(error => console.log('error', error));
        this.toggleFileDash();
    }


    // Delete the current gallery
    delGalleryData(){
        fetch( "/api/gallery/" + this.props.gallery, {method: "DELETE", redirect: 'follow'})
        .then(response => response.json())
        .then(json => {
            this.closeViewer();
        })
        .catch(error => {
            console.log(error);
        });
    }

    closeViewer(){
        // Pass A Prop For the viewer wanting to close
        if(typeof this.props.closeAction === "function") {
            this.props.closeAction();
        }
    }


    actionButton(node){
        let target = node.currentTarget;
        let {action} = target.dataset;

        switch (action) {
            case "delete":
                this.delGalleryData();
                break;
            case "save":
                this.toggle(false);
                break;
            case "edit":
                this.toggle(true);
                break;
            case "image-upload":
                this.toggleFileDash();
                break;
            default:
        }
    }

    toggleFileDash(){
        let s = this.state;

        if(s.fileDash){
            s.thumbailChange = false;
        }
        s.fileDash = !s.fileDash;

        this.setState(s);
    }

    updateMedia(changes){
        let state = this.state;
        state.media = changes;
        state.index = 0;
        this.setState(state);
    }

    viewMedia(id){
        let index = this.state.media.indexOf(id);
        if (index > -1) {
            let state = this.state;
            state.index = index;
            this.setState(state);
        }
    }


    async addMedia(media){
        console.log(media);
        let data = JSON.stringify({
            "media": media
        });

        let config = {
            method: 'post',
            url: `/api/gallery/${this.props.gallery}/media`,
            headers: {
                'Content-Type': 'application/json',
            },
            data : data
        };

        await axios(config)
        .then((response) => {
            console.log(JSON.stringify(response.data));
            this.setState(this.state);
        })
        .catch((error) => {
            console.log(error);
        });
        this.toggleFileDash();
    }

    render() {

        let currentMedia = this.state.media[this.state.index];

        return (
            <gallery-viewer-container>
                {
                    this.state.fileDash ? <FileDash onClose={this.toggleFileDash} onSelection={ this.state.thumbailChange ? this.updateThumbnail : this.addMedia} multiSelect={!this.state.thumbailChange}/> : <></>
                }
                <gallery-viewer>
                    <button id="gallery-item-close" onClick={this.closeViewer}><XCircleIcon size={"25px"} /></button>
                    <div id="image-viewer">
                        <div id="image-viewer-buttons">
                            <div className="direction-button" name="back"    id="bck" onClick={this.back}><ChevronLeftIcon/></div>
                            <div className="direction-button" name="forward" id="fwd" onClick={this.forward}><ChevronRightIcon/></div>
                        </div>
                        <div id="viewer-items">
                            <div className="viewer-item" >
                                <MediaRender src={currentMedia}/>
                            </div>
                        </div>
                    </div>
                    <AdminNeeded>
                        <div id="action-items">
                            <button
                                data-action={"upload-image"}
                                onClick={() => {
                                    let s = this.state;
                                    s.thumbailChange = true;
                                    this.toggleFileDash();
                                    this.setState(s);
                                }}
                                children={<div>Add Icon</div>}
                            />
                            <button
                                data-action={"upload-image"}
                                onClick={this.toggleFileDash}
                                children={<ImageIcon size={"30px"} />}
                            />
                            <button
                                data-action={this.state.editmode ? "save" : "edit"}
                                onClick={this.actionButton}
                                children={!this.state.editmode ?  <PencilIcon size={"30px"} /> : <UploadIcon  size={"30px"}/>}
                            />
                            <button
                                data-action="delete"
                                onClick={this.actionButton}
                                children={<TrashIcon size={"30px"} />}
                            />
                        </div>
                        <MediaManager thumbnail={this.state.thumbnail} media={this.state.media} gallery={this.props.gallery}  onChanges={this.updateMedia} viewMedia={this.viewMedia} newThumbnail={this.updateThumbnail}/>
                    </AdminNeeded>
                    <div id="image-viewer-body">
                        {
                            this.state.editmode ?
                                <input id="image-viewer-title" type="text" value={this.state.title} placeholder="Project Title" onChange={(node) => { let state = this.state; state.title = node.currentTarget.value; this.setState(state); }}></input>
                                    :
                                <h1 id="image-viewer-title">{this.state.title || "Project Item"}</h1>
                        }
                        <hr/>
                        <GalleryBody loaded={this.state.loaded} editmode={this.state.editmode} data={this.state.body} onSave={this.setGalleryData}/>
                    </div>
                </gallery-viewer>
            </gallery-viewer-container>
        );
    }
}

class MediaManager extends Component {
    state = {};

    constructor(){
        super();
        this.delMedia = this.delMedia.bind(this);
        this.updateMedia = this.updateMedia.bind(this);
        this.makeIcon = this.makeIcon.bind(this);
        this.viewMedia = this.viewMedia.bind(this);
    }

    componentDidMount(){
        // console.log(this.props.media);
    }
    componentDidUpdate(){
        // console.log(this.props.media);
    }

    delMedia(node){
        let key = node.currentTarget.dataset.key;


        let data = JSON.stringify({
            "media": [key]
        });
        let config = {
            method: 'delete',
            url: `/api/gallery/${this.props.gallery}/media`,
            headers: {
                'Content-Type': 'application/json',
            },
            data : data
        };
        axios(config)
        .then((response) => {
            let changes = [...this.props.media];
            let index = changes.indexOf(key);
            if (index > -1) {
                changes.splice(index, 1);
            }
            this.updateMedia(changes);
        })
        .catch((error) => {
            console.error(error);
        });
    }

    makeIcon(node){
        let key = node.currentTarget.dataset.key;
        if(typeof this.props.newThumbnail === "function"){
            this.props.newThumbnail(key);
        }
    }

    updateMedia(changes){
        if(typeof this.props.onChanges === "function"){
            this.props.onChanges(changes);
        }
    }

    viewMedia(node){
        let key = node.currentTarget.dataset.key;
        if(typeof this.props.viewMedia === "function"){
            this.props.viewMedia(key);
        }
    }


    render(){
        return (
            <div id="image-manager">
                {
                    this.props.media.map((id) => {
                        return (
                            <div key={id} id="media-item"  data-key={id} onClick={this.viewMedia}>
                                <div className="media-item-control">
                                    <div>
                                        <button onClick={this.delMedia}  data-key={id}><XCircleIcon /></button>
                                    </div>
                                </div>
                                <img src={`${id}?thumbnail=true`} alt={id}/>
                            </div>
                        )
                    })
                }
            </div>
        );
    }
}

function AdminNeeded(props){

    let childs = <>{props.children}</>;
    return (
        <UserContext.Consumer>
        {
            data => {
                if (data.admin){
                    return <>{childs}</>;
                }
                return <></>;
            }
        }
        </UserContext.Consumer>
    );
}


export default GalleryViewer;
