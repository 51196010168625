import React, { Component } from 'react';
import { Link } from "react-router-dom";


import "./Landing.scss";

import placeholderImage from "./placeholder.webp";
import placeholderImage2 from "./placeholder2.jpeg";


import ScalableImage from "../../components/ScalableImage/ScalableImage"

class Landing extends Component {

    state = {
        scriptLine: 0,
        welcomeScript: [
            {
                message: "Welcome",
                duration: 1
            },
            {
                message: "To",
                duration: 1
            },
            {
                message: "Hellman Plumbing And General Construction",
                duration: 4,
            },
            {
                message: "Have Your House Feel Like Home ❤",
                duration: 4,
            }
        ],
        displayedMessage: "",
    }



    welcomeAnimation(){
        let pos = this.state.scriptLine;
        let message = this.state.welcomeScript[pos];
        pos = pos + 1 >= this.state.welcomeScript.length ? 0 : pos + 1;
        this.setState(state => {
            state.scriptLine = pos;
            state.displayedMessage = message.message;
            return state;
        });
        setTimeout(() => {
            if(this.mounted){
                this.welcomeAnimation();
            }
        }, message.duration * 1000 || 1000);
    }

    componentDidMount() {
        this.mounted = true;
        this.welcomeAnimation();
    }
    componentWillUnmount() {
        this.mounted = false;
    }


    render() {
        return (
            <>
                <landing-page>
                    <div className="impression-overlay">
                        <div className="flashing-text-container">
                            <div className="flashing-text">{this.state.displayedMessage}</div>
                        </div>
                        <ScalableImage img_src={placeholderImage} placeholder_img={placeholderImage}/>
                    </div>


                    <div id="landing-body">
                        <div id="landing-contact-button-container">
                            <Link id="landing-contact-button" to="/quote">Get A Quote</Link>
                        </div>
                        <ScalableImage img_src={placeholderImage2} placeholder_img={placeholderImage2}/>
                    </div>
                </landing-page>
            </>
        );
    }

}

export default Landing;
