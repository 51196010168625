import React from 'react';

function Context () {
    this.admin = false
    this.setAdmin  = (state) => {
        this.admin = state;
    }
}

let context = new Context();

const UserContext = React.createContext(context);

export default UserContext;
