import React, { Component } from 'react';

import { Route } from "react-router-dom";


import { PlusCircleIcon } from '@primer/octicons-react';



import "./Gallery.scss";

import GalleryViewer from './GalleryViewer/';
import GalleryPanel from "./GalleryPanel/";

import UserContext from '../../UserContext';

class Gallery extends Component {

    state = {
        galleries: [],
        viewerOpen: false
    }

    constructor(){
        super();
        this.closeItem = this.closeItem.bind(this);
        this.generateNewGalleryProject = this.generateNewGalleryProject.bind(this);
        this.actionBar = this.actionBar.bind(this);
    }

    componentDidMount(){
        this.getGalleries();
    }

    componentDidUpdate(){
        let { match } = this["props"];
        let galleryId = match.params.galleryId;

        // open viewer if the url contains
        if(this.state.viewerOpen === false && typeof galleryId === "string"){
            let state = this.state;
            state.viewerOpen = true;
            this.setState(state);
        } else if(this.state.viewerOpen === true && ( galleryId === "" || !galleryId )){
            let state = this.state;
            state.viewerOpen = false;
            this.getGalleries();
            this.setState(state);
        }
    }


    async getGalleries(){
        await fetch( "/api/gallery/all", {method: "GET", redirect: 'follow'})
        .then(response => response.json())
        .then(_json => {
            let state = this.state;
            let galleries = _json.data;
            let gals = [];
            for(let g in galleries){
                let gallery = galleries[g];
                gallery.id = g;
                gals.unshift(gallery);
            }
            state.galleries = gals;
            this.setState(state);
        })
        .catch(error => {
            console.log('error', error);
        });
    }



    generateNewGalleryProject(){
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({});

        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };

        fetch("/api/gallery", requestOptions)
          .then(response => response.json())
          .then(result => {
                this.props.history.push('/gallery/' + result.gallery_id);
          })
          .catch(error => console.log('error', error));
    }

    closeItem(){
        this.props.history.push('/gallery');
    }

    actionBar(){
        let editbutton =  (
            <UserContext.Consumer>
                {
                    data => {
                        if (data.admin){
                            return (
                                <div id="gallery-action-items">
                                    <button onClick={this.generateNewGalleryProject}><PlusCircleIcon size={"50px"} /></button>
                                </div>
                            );
                        }
                        return <></>
                    }
                }
            </UserContext.Consumer>
        )
        return editbutton;
    }

    render() {
        let {match} = this["props"];
        let galleryId = match.params.galleryId;
        return (
            <gallery-page>
                <div id="gallery-banner" style={{display: "flex"}}>
                    <img id="gallery-banner-image" src={process.env.PUBLIC_URL + '/plumbing-1.jpeg'} alt="Gallery Banner"/>
                    <h1 style={{color: "white", margin: "auto"}}>Project Galleries</h1>
                </div>
                {this.actionBar()}
                <div id="gallery-body">
                    { this.state.galleries.map(i => <GalleryPanel key={i.id} data={i} thumbnail={`${i.thumbnail}?thumbnail`} onClick={this.toggleItem}/>)}
                </div>
                <Route exact path={`/gallery/:galleryId`}>
                    <GalleryViewer gallery={galleryId} closeAction={this.closeItem}/>
                </Route>
            </gallery-page>
        );
    }

}

export default Gallery;
