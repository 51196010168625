import React, { Component } from 'react';
import { Formik, Field, Input, TextArea} from 'formik';
import * as Yup from 'yup';
import { v4 as uuidv4 } from 'uuid';
import {CheckCircleFillIcon } from '@primer/octicons-react';


import "./ContactForm.scss";


const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const states = [
    'AL','AK','AS','AZ','AR','CA','CO','CT','DE','DC','FM','FL','GA',
    'GU','HI','ID','IL','IN','IA','KS','KY','LA','ME','MH','MD','MA',
    'MI','MN','MS','MO','MT','NE','NV','NH','NJ','NM','NY','NC','ND',
    'MP','OH','OK','OR','PW','PA','PR','RI','SC','SD','TN','TX','UT',
    'VT','VI','VA','WA','WV','WI','WY'
];


const yupSchema = Yup.object({
    firstName: Yup.string()
        .max(50, 'First Name Must be 50 characters or less')
        .required('First Name is Required'),
    lastName: Yup.string()
        .max(50, 'Last Name Must be 50 characters or less')
        .required('Last Name is Required'),
    organization: Yup.string()
        .max(120, 'Organization Must be 120 characters or less'),
    email: Yup.string()
        .max(250, 'Email Must be 250 character or less')
        .email('Invalid Email Address')
        .required('Email is Required'),
    phone: Yup.string()
        .matches(phoneRegExp, 'Phone number is not valid')
        .required('Phone is Required'),
    address: Yup.string()
        .max(40, 'Address Must be 40 characters or less')
        .required('Address is Required'),
    city: Yup.string()
        .max(40, 'City Must be 40 characters or less')
        .required('City is Required'),
    state: Yup.string()
        .uppercase()
        .oneOf(states, 'State Must Be an abbreviation of a State')
        .required('State is Required'),
    postalCode: Yup.string()
        .matches(/^[0-9]+$/, "Postal Code Must be only digits")
        .min(4, 'Postal Code Must be between 4 and 9 characters')
        .max(9, 'Postal Code Must be between 4 and 9 characters')
        .required('Postal Code is Required'),
    subject: Yup.string()
        .min(4,  'Subject Must be between 4 and 100 characters')
        .max(120, 'Subject Must be between 4 and 100 characters')
        .required('Subject is Required'),
    description: Yup.string()
        .min(25,  'Description Must be between 25 and 2048 characters')
        .max(2048, 'Description Must be between 25 and 2048 characters')
        .required('Description is Required'),
});


const initValues = {
    firstName: "",
    lastName: "",
    organization: "",
    email: "",
    phone: "",
    address: "",
    city: "Hermiston",
    state: "OR",
    postalCode: 97838,
    subject: "",
    description: "",
};

class ContactForm extends Component {

    state = {
        message: "",
        submitted: false
    }
    constructor(){
        super();

        this.submitForm = this.submitForm.bind(this);
    }

    componentDidMount(){}

    submitForm(data, formKit){

        console.log(data)

        let headers = new Headers();
        headers.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'POST',
            headers: headers,
            body:  JSON.stringify(data),
            redirect: 'follow'
        };
        return fetch("/api/main/client/quote", requestOptions)
          .then(response => response.json())
          .then(result => {
              if (result.status === "success"){
                let state = this.state;
                  state.message = result.message;
                  state.submitted = true;
              }
              this.setState(this.state);
              formKit.resetForm();
          })
          .catch(error => console.log('error', error));
    }
    render() {
        return (
            <contact-form>
                <contact-form-top>
                    <h1>Contact Form</h1>
                </contact-form-top>
                <contact-form-container>

                    <contact-form-main>
                        <Formik
                            initialValues={initValues}
                            validationSchema={yupSchema}
                            onSubmit={this.submitForm}
                        >
                        {
                            ({errors, handleSubmit, touched}) => {
                                let showError = (location) =>  errors[location] && touched[location] ? " contact-error " : "";

                                return (
                                    <>
                                        <div className="contact-form-errors">
                                        {
                                            Object.keys(errors).map(c => touched[c] && errors[c] ? <div key={uuidv4()}>{errors[c]}</div> : <></>)
                                        }
                                        </div>
                                        <form>
                                            <Field as="input"    type="first-name"     name="firstName"     placeholder="First Name"              className={""  + showError("firstName")} />
                                            <Field as="input"    type="last-name"      name="lastName"      placeholder="Last Name"               className={""  + showError("lastName")}/>
                                            <Field as="input"    type="organization"   name="organization"  placeholder="Organization (Optional)" className={""  + showError("organization")}/>
                                            <Field as="input"    type="email"          name="email"         placeholder="Email"                   className={""  + showError("email")}/>
                                            <Field as="input"    type="tel"            name="phone"         placeholder="Phone"                   className={""  + showError("phone")}/>
                                            <Field as="input"    type="address-line1"  name="address"       placeholder="Address"                 className={""  + showError("address")}/>
                                            <Field as="input"    type="address-level2" name="city"          placeholder="City"                    className={""  + showError("city")}/>
                                            <Field as="input"    type="address-level1" name="state"         placeholder="State"                   className={""  + showError("state")}/>
                                            <Field as="input"    type="postal-code"    name="postalCode"    placeholder="Postal Code"             className={""  + showError("postalCode")}/>
                                            <Field as="input"    type="text"           name="subject"       placeholder="Subject"                 className={""  + showError("subject")}/>
                                            <Field as="textarea" type="description"    name="description"   placeholder="Reason For Contact"     className={""  + showError("description")}/>
                                            <button type="submit" onClick={handleSubmit}>Submit</button>
                                        </form>
                                    </>
                                );
                            }
                        }
                        </Formik>
                    </contact-form-main>
                    {
                        this.state.submitted ?
                            <contact-form-cover>
                                <contact-form-cover-body><CheckCircleFillIcon size={150} /></contact-form-cover-body>
                            </contact-form-cover>
                            : <></>
                    }
                </contact-form-container>
            </contact-form>
        );
    }
}

export default ContactForm;
