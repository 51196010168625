
import React, { useState, useRef, useEffect } from 'react'
import { createPortal } from 'react-dom'


export default function Quote(props, children) {
    let redirect = "https://app.gethearth.com/requests/3b4d9d97-7678-47d2-a00b-9cf5065e29de";
    // this page will redirect to the hearth widget
    window.location.replace(redirect);
    return (
        <div>
            <h1>Redirecting...</h1>
        </div>
    );
}


