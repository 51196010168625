import ReactTooltip from 'react-tooltip';
import React, { Component } from 'react';
import "./NavBar.scss";

import { DotIcon, DotFillIcon } from '@primer/octicons-react'

import { NavLink } from "react-router-dom";

import NavDrawers from "../NavDrawer/NavDrawer";

class NavBar extends Component {

    constructor(){
        super();
        this.toggleDrawer = this.toggleDrawer.bind(this);
    }

    state = {
        drawerOpen: false,
    }

    preventDefault = e => e.preventDefault();

    toggleDrawer(e){
        this.setState({drawerOpen: !this.state.drawerOpen});
    }

    render() {
        return (
            <>
                <ReactTooltip />
                <nav aria-hidden="true" onDragStart={this.preventDefault} onContextMenu={this.preventDefault}>
                    <div id="nav-left">
                    </div>
                    <div id="nav-center">
                        <div id="company-logo-container">
                            <NavLink to="/" data-tip="Hellman Plumbing And General Construction">
                                <h3 id="company-logo-text">HPGC</h3>
                            </NavLink>
                        </div>
                    </div>
                    <div id="nav-right">
                        <div id="nav-buttons">
                            <span onClick={this.toggleDrawer}>
                                <DrawerButton open={this.state.drawerOpen} />
                            </span>
                        </div>
                    </div>
                </nav>
                <nav-placeholder/>
                <NavDrawers open={this.state.drawerOpen} onLinkClick={this.toggleDrawer}/>
            </>
        );
    }

}

class DrawerButton extends Component {

    render() {
        return this.props.open ?
        // open
        <>
            <DotFillIcon size={20} />
            <DotFillIcon size={20} />
            <DotFillIcon size={20} />
        </>
        :
        // closed
        <>
            <DotIcon size={20} />
            <DotIcon size={20} />
            <DotIcon size={20} />
        </>;
    }

}

export default NavBar;
