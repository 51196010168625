import React, { Component } from 'react';
import {CheckCircleFillIcon } from '@primer/octicons-react';

import "./Cover.scss";

class Cover extends Component {
    render() {
        return (
            <form-cover style={{display: this.props.display || "none"}}><CheckCircleFillIcon size="100px"/></form-cover>
        );
    }

}

export default Cover;
