import React, { Component } from 'react';

class Logout extends Component {
    componentDidMount() {
        document.cookie = "token=";
        window.location.replace("/");
    }

    render() {
        return (<></>);
    }

}

export default Logout;
