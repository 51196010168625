import React, {useState, useEffect } from 'react';

import axios from 'axios';

function Upload({ file, url } = {}){

    const { name } = file;
    const CancelToken = axios.CancelToken;
    const cancelToken = CancelToken.source();

    const [state, setState] = useState({
        progress: 0,
        status: "uploading",
        sent: false,
        cancel: cancelToken.cancel
    });

    let sendFile = () => {
        let formdata = new FormData();
        formdata.append(name, file);
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            cancelToken: cancelToken.token,
            onUploadProgress: function (progressEvent) {
                let percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
                let s = {...state};
                s.progress = percentCompleted;
                if(s.progress === 100){
                    s.status = "converting";
                }
                s.cancel = cancelToken.cancel
                setState(s);
            }
        };

        axios.post(url, formdata, config)
        .then(() => {
            let s = {...state};
            s.progress = 100;
            s.status = "finished";
            setState(s);
        })
        .catch((error) => {
            if (axios.isCancel(error)) {
                let s = {...state};
                s.status = "canceled";
                setState(s);
            } else {
                console.error(error);
                let s = {...state};
                s.status = "error";
                setState(s);
            }
        });
        let s = {...state};
        s.sent = true;
        setState(s);
    };


    useEffect(() => {
        sendFile();
    }, [])

    return (
        <div className={"file-upload " + state.status}>
            <div className="upload-details">
                <div className="file-name">{name}</div>
            </div>
            <div className="upload-percentage">
                <div>{state.progress}% | {state.status}</div>
            </div>
            <div className={"controls " + state.status}>
                <button onClick={state.cancel} children={["x"]}/>
            </div>
        </div>
    );
}

export default Upload;