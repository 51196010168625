import React, { Component } from 'react';

import { Formik, Field} from 'formik';

import Cover from "../Cover/";


class SMTPConfig extends Component {

    state = {
        domain: "",
        loaded: false,
        success: false
    }

    constructor(){
        super();
        this.init   = this.init.bind(this);
        this.submit = this.submit.bind(this);
    }

    componentDidMount(){
        this.init();
    }

    async init(){
        await fetch("/api/server/domain")
          .then(response => response.json())
          .then(json => {
              if(json.status === "success"){
                  let { domain } = json;
                  this.setState({domain, loaded: true});
              }
          })
          .catch(error => {
              let state = this.state;
              state.loaded = true;
              this.setState(this.state);
          });
    }


    async submit(data){
        let { domain } = data;
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        let raw = JSON.stringify({
            domain
        });

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        await fetch("/api/server/domain", requestOptions)
            .then(response => response.json())
            .then(json => {
                let state = this.state;
                if( json.status=== "success" ){
                    state.success = true;
                    this.setState(state);
                }
            })
            .catch(error => console.log('error', error));
    }

    render() {
        if (this.state.loaded === false) return <></>;
        return (
            <smtp-config>
                <Formik
                    initialValues={this.state}
                    onSubmit={this.submit}
                >
                {
                formik => (
                    <>
                        <h2>Domain</h2>
                        <i>Please include http(s): subdomain, and port</i>
                        <div>
                        {
                            Object.keys(formik.errors).map(c => formik.touched[c] && formik.errors[c] ? <div>{formik.errors[c]}</div> : <></>)
                        }
                        </div>
                        <form autoComplete="off" onSubmit={formik.handleSubmit}>
                            <Input type="domain" formik={formik}>Website Domain</Input>
                            <button type="submit">Update Domain</button>
                            <Cover display={this.state.success ? "flex" : "none"}/>
                        </form>
                    </>
                )
                }
                </Formik>
            </smtp-config>
        );
    }
}

function Input(props){
    let type = props.type;
    let formik = props.formik;
    return (
        <>
            <input-container>
                <Field component="input" className={type} type={type}  {...formik.getFieldProps(type)} placeholder={props.children}/>
            </input-container>
        </>
    )
}

export default SMTPConfig;
